@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&family=Noto+Serif:wght@400;700&display=swap');


:root {
  --accent-color: red;
  --text-color: white;
  --scrollbar-thumb: #c1c1c1;
  --scrollbar-track: #fafafa;
}

#list { 
  overflow: scroll;
}

/* width */
#list::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#list::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
}

/* Handle */
#list::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border-radius: 4px;
}

/* Handle on hover */
#list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#list ul {
  list-style-type: none;
  margin: 0;
  width: 100%;
  padding: 0;
}

#list ul li {
  padding: 0.5rem 1rem;
  user-select: none;
  cursor: pointer;
  margin: 0;
  text-align: left;
  font-family: 'Noto Serif';
  font-size: 17px;
  transition: all 0.1s linear;
}

#list ul li {
  display: grid;
  grid-template-columns: 75% 25%;
}

/* Doesn't have 3 elements, use only 1 column */
#list ul li:not(:has(:nth-child(3))) {
  grid-template-columns: 1fr;
}

span + small.pastor {
  grid-row: 2;
}

#list ul li:nth-child(odd) {
  background-color: rgb(228, 228, 228);
}

#list ul li:hover {
  color: white;
  background: hsl(210deg 100% 12%);
}

#list ul li small {
  display: block;
  font-size: 0.7em;
  color: gray;
  font-style: italic;
  font-family: "Noto Sans";
  font-size: 13px;
}


#content {
  display: flex;
  flex-direction: row-reverse;
}

#map {
  width: 100vw;
  height: 100vh;
}

#searchList {
  display: flex;
  flex-direction: column;
  width: 30%;
  min-width: 250px;
  max-height: 100vh;
}

#searchButtons {
  background-color: #003366;
}

#searchButtons #near, #searchButtons #searchInput {
  background-color: #cce5ff;
  border: 1px solid #cce5ff;
  border-radius: 20px;
  color: #036;
  cursor: pointer;
  display: flex;
  gap: 10px;
  margin: 10px;
  padding: 4px 10px;
  transition: all .1s linear;
  font-size: 1rem;
  align-items: center;
}

#searchButtons #near {
  user-select: none;
}

#searchButtons #near:hover {
  box-shadow: 0px 0px 5px #cce5ff;
}

#searchButtons {
  position: sticky;
  top: 0px;
}

#search {
  appearance: none;
  border: 0;
  background-color: transparent;
  color: rgb(0, 51, 102);
  outline: none;
  border-radius: 20px;
}

#geocode {
  position: fixed;
  top: 50px;
  appearance: none;
  height: 50px;
  width: 25%;
  border: 0;
  background-color: #003366;
  color: white;
  font-size: 1.5em;
  outline: none;
  padding: 10px;
  padding-left: 50px;
  background-repeat: no-repeat;
  background-position: 10px 50%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-filter' width='24' height='24' viewBox='0 0 24 24' stroke-width='1.5' stroke='white' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5' /%3E%3C/svg%3E");}

#search::placeholder {
  color: #003366;
  opacity: 0.5;
}

.churchInfo {
  padding: 10px;
}

.churchInfo h1, .churchInfo h2, .churchInfo p {
  margin: 0;
}
.churchInfo address {
  font-style: normal;
}

.churchInfo .links {
  margin-top: 2em;
  display: flex;
  justify-content: flex-start;
  gap: 1em;
}

.churchInfo .links a {
  height: fit-content;
  display: inline-block;
  width: fit-content;
}

.churchInfo h1 {
  font-size: 1.8em;
  margin-bottom: 0.5em;
  color: #003366;
}

.churchInfo h2 {
  margin-top: 1em;
  padding-bottom: 5px;
  font-size: 1.2em;
  font-weight: normal;
  color: #003366;
  border: none;
  padding-left: 0;
}

#logo {
  background: #003366;
  padding: 10px;
}

small.distance {
  width: fit-content;
  background: #00336621;
  padding: 4px 8px;
  border-radius: 8px;
  color: #646464 !important;
  font-size: 0.8rem !important;
  place-self: end;
}

li:hover small.distance {
  background-color: white;
}

@media only screen and (max-width: 600px) {
  #content {
    flex-direction: column-reverse;
  }

  #map {
    height: 80vh;
  }

  #searchList {
    width: 100%;
  }
  
  #search {
    width: 100%;
  }

}
